export default {
  id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  description: {
    label: 'Descripción',
    type: String,
    optional: true,
    textarea: true
  },
  color: {
    label: 'Color',
    type: String,
    color: true
  },
  area: {
    label: 'Área',
    type: String,
    geofence: true,
    optional: false
  },
  // calendarId: {
  //   label: 'Calendario',
  //   type: Number,
  //   optional: true,
  //   model: 'calendars',
  //   autocomplete: false
  // },
  attributes: {
    label: 'Atributos',
    type: Object,
    optional: true,
    attributes: true
  }
};
